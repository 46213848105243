<script setup lang="ts">
import type {
  IManagerFieldEmits,
  IManagerFieldProps,
} from '@manager/components/Field/types'
import {
  useField,
  useFieldCache,
  useFieldCalculation,
  useFieldCast,
} from '@manager'

const props = withDefaults(defineProps<IManagerFieldProps>(), {
  parentValue: () => ({}),
})
const emit = defineEmits<IManagerFieldEmits>()

const {
  modelValue,
  parentValue,
  id,
  type,
  colSize,
  label,
  path,
  validation: { errorMessage },
  help,
  disabled,
  min,
  max,
  handleChange,
  handleBlur,
} = useField(props, emit)

const { castPlugin } = useFieldCast(props.node)

const { registerCalculated } = useFieldCalculation(props.node, parentValue)
registerCalculated()

// Initialize data field, after setting default value
const { registerData } = useFieldData(props.node, parentValue)
registerData()

useFieldCache(props.node, parentValue, props.preserveCache)
</script>

<template>
  <ManagerSharedFieldBase
    :path="path"
    :col-size="colSize"
    :tooltip="node.tooltip"
    :help="help"
    :error-message="errorMessage"
    :usage="node.usage"
  >
    <FormKit
      :id="id"
      v-model="modelValue"
      type="number-increment"
      :name="node.name"
      :label="label"
      label-class="overflow-ellipsis whitespace-nowrap overflow-hidden"
      :plugins="[castPlugin]"
      :min="min"
      :max="max"
      :disabled="disabled"
      @input="handleChange"
      @blur="handleBlur"
    />
  </ManagerSharedFieldBase>
</template>
